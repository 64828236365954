import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store';
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/Legal/Privacy.vue')
    },
    {
        path: '/terms-conditions',
        name: 'terms-conditions',
        component: () => import(/* webpackChunkName: "terms-conditions" */ '../views/Legal/Terms.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {guestsOnly: true}
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
        meta: {guestsOnly: true}
    },
    {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import(/* webpackChunkName: "verify-email" */ '../views/VerifyEmail.vue')
    },
    {
        path: '/map/:id',
        name: 'map-id',
        component: () => import(/* webpackChunkName: "map-id" */ '../views/MapboxGL.vue')
    },
    {
        path: '/map',
        name: 'map',
        component: () => import(/* webpackChunkName: "map" */ '../views/MapboxGL.vue')
    },
    {
        path: '/browse',
        redirect: '/repeaters'
    },
    {
        path: '/repeaters',
        name: 'repeaters',
        component: () => import(/* webpackChunkName: "repeaters" */ '../views/Repeaters.vue')
    },
    {
        path: '/network',
        name: 'network',
        component: () => import(/* webpackChunkName: "network" */ '../views/Nets.vue')
    },
    {
        path: '/view',
        redirect: to => {
            return '/repeater/' + to.query.id;
        }
    },
    {
        path: '/repeater/:id',
        name: 'repeater',
        component: () => import(/* webpackChunkName: "repeater" */ '../views/Repeater.vue')
    },
    {
        path: '/callsign',
        redirect: to => {
            return to.query.callsign ? '/license/' + to.query.callsign.toUpperCase() : '/license';
        }
    },
    {
        path: '/license/:callsign',
        name: 'license',
        component: () => import(/* webpackChunkName: "license" */ '../views/License.vue')
    },
    {
        path: '/users',
        redirect: to => {
            return to.query.username ? '/user/' + to.query.username : '/user';
        }
    },
    {
        path: '/user/:username',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
    },
    {
        path: '/password',
        redirect: '/reset-password'
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
        meta: {guestsOnly: true}
    },
    {
        path: '/profile/password',
        name: 'profile-password',
        component: () => import(/* webpackChunkName: "profile-password" */ '../views/Profile/ChangePassword.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/preferences',
        redirect: '/profile/edit'
    },
    {
        path: '/profile',
        redirect: '/profile/edit'
    },
    {
        path: '/profile/edit',
        name: 'profile-edit',
        component: () => import(/* webpackChunkName: "profile-edit" */ '../views/Profile/EditProfile.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/repeaters',
        name: 'profile-repeaters',
        component: () => import(/* webpackChunkName: "profile-repeaters" */ '../views/Profile/MyRepeaters.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/requests/repeater/:id',
        name: 'profile-requests-repeaters-id',
        component: () => import(/* webpackChunkName: "profile-requests-repeaters" */ '../views/Profile/Requests.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/requests/repeater',
        name: 'profile-requests-repeaters',
        component: () => import(/* webpackChunkName: "profile-requests-repeaters" */ '../views/Profile/Requests.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/inbox',
        name: 'profile-inbox',
        component: () => import(/* webpackChunkName: "profile-inbox" */ '../views/Profile/Inbox.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/subscriptions',
        name: 'profile-subscriptions',
        component: () => import(/* webpackChunkName: "profile-subscriptions" */ '../views/Profile/Subscriptions/Overview.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/subscriptions/success',
        name: 'profile-subscriptions-success',
        component: () => import(/* webpackChunkName: "profile-subscriptions-success" */ '../views/Profile/Subscriptions/Success.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/profile/subscriptions/canceled',
        name: 'profile-subscriptions-canceled',
        component: () => import(/* webpackChunkName: "profile-subscriptions-canceled" */ '../views/Profile/Subscriptions/Canceled.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
        meta: {requiresAuth: true}
    },
    // {
    //     path: '/nets',
    //     name: 'nets',
    //     component: () => import(/* webpackChunkName: "nets" */ '../views/Nets.vue')
    // },
    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
    },
    {
        path: '/help/about-gmrs',
        name: 'help-aboutgmrs',
        component: () => import(/* webpackChunkName: "help-aboutgmrs" */ '../views/Help/AboutGMRS.vue')
    },
    {
        path: '/help/get-gmrs-license',
        name: 'help-getlicense',
        component: () => import(/* webpackChunkName: "help-getlicense" */ '../views/Help/GetGMRSLicense.vue')
    },
    {
        path: '/help/branding-guide',
        name: 'help-brandingguide',
        component: () => import(/* webpackChunkName: "help-brandingguide" */ '../views/Help/BrandingGuide.vue')
    },
    {
        path: '/admin',
        name: 'admin-dashboard',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/Admin/Dashboard.vue'),
        meta: {requiresAuth: true, requiresAdmin: true},
    },
    {
        path: '/admin/repeaters/:id',
        name: 'admin-repeaters',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/Admin/Dashboard.vue'),
        meta: {requiresAuth: true, requiresAdmin: true},
    },
    {
        path: '/admin/users/:id',
        name: 'admin-users',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/Admin/Dashboard.vue'),
        meta: {requiresAuth: true, requiresAdmin: true},
    },
    {
        path: '/admin/comments/:id',
        name: 'admin-comments',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/Admin/Dashboard.vue'),
        meta: {requiresAuth: true, requiresAdmin: true},
    },
    {
        path: '/admin/reports/:id',
        name: 'admin-reports',
        component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/Admin/Dashboard.vue'),
        meta: {requiresAuth: true, requiresAdmin: true},
    },
    {
        path: '*',
        name: 'notfound',
        component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    routes
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAdmin)) {
//         if (store.getters.isAdmin) {
//             next();
//             return;
//         }
//         next('/login');
//     } else {
//         next();
//     }
// });
//
// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (store.getters.isAuthenticated) {
//             next();
//             return;
//         }
//         next('/login');
//     } else {
//         next();
//     }
// });
//
// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.guestsOnly)) {
//         if (store.getters.isAuthenticated) {
//             next('/profile/edit');
//             return;
//         }
//         next();
//     } else {
//         next();
//     }
// });

export default router
