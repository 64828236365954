import Vue from 'vue';
// import store from './store';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import _ from 'lodash';
import moment from 'moment';
import router from './router';
import VueSanitize from 'vue-sanitize';
import '@mdi/font/css/materialdesignicons.css';
import Ads from 'vue-google-adsense'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, {
    id: 'UA-39545910-1',
    router
});

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

let defaults = VueSanitize.defaults;
defaults.allowProtocolRelative = false;
defaults.allowedTags = defaults.allowedTags.concat(['img']);
defaults.allowedSchemes.push('data');
if (!defaults.allowedAttributes.hasOwnProperty('p')) {
    defaults.allowedAttributes.p = ['class'];
} else {
    defaults.allowedAttributes.p.push('class');
}
// defaults.allowedTags = defaults.allowedTags.filter(t => {
//     return t !== 'strong';
// });

// defaults.disallowedTagsMode = 'escape';

Vue.use(VueSanitize, defaults);

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

// Register a global custom directive called `v-blur` that prevents focus
Vue.directive('blur', {
    inserted: function (el) {
        el.onfocus = (ev) => ev.target.blur()
    }
});

Vue.config.productionTip = false;

Vue.prototype.$_ = _;
Vue.prototype.$moment = moment;

new Vue({
    vuetify,
    // store,
    router,
    render: h => h(App)
}).$mount('#app');
