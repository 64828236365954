<template>
    <v-badge
        avatar
        overlap
        bottom
        color="transparent"
        :offset-x="(avatarSize / 2) + (avatarSize / 4)"
        :offset-y="(avatarSize / 2) + (avatarSize / 4)"
    >
        <template v-slot:badge>
            <v-avatar v-if="user.subscribed">
                <v-icon color="yellow darken-1" small>mdi-star</v-icon>
            </v-avatar>
        </template>

        <v-list-item-avatar
            :size="avatarSize"
            :color="hideAvatarText ? 'transparent' : 'rfaccent2'"
            :tile="tile"
            class="elevation-5"
        >
            <v-img
                eager
                aspect-ratio="1"
                :src="'https://files.mygmrs.com/avatar/' + ($_.isString(username) ? username.toLowerCase() : '')"
                :alt="username + ' Avatar'"
                @load="hideAvatarText = true"
                @error="hideAvatarText = false"
                v-show="hideAvatarText"
                v-if="username"
            />
            <span
                :class="this.$_.isUndefined(this.small) ? (this.$_.isUndefined(this.xsmall) ? 'white--text display-3 font-weight-thin pt-1' : 'white--text avatar-text-xs font-weight-light pt-1') : 'white--text avatar-text font-weight-light pt-1'"
                v-if="!hideAvatarText"
            >
                        {{ callsign | splitCallsign }}
                    </span>
        </v-list-item-avatar>
    </v-badge>
</template>

<style scoped>
    .avatar-text {
        line-height: 12px !important;
        font-size: 12px;
        padding-top: 3px;
    }

    .avatar-text-xs {
        line-height: 10px !important;
        font-size: 10px;
        padding-top: 3px;
    }
</style>

<script>
    import md5 from 'md5';

    export default {
        props: ['user', 'tile', 'small', 'xsmall'],

        data: () => ({
            hideAvatarText: true,
            username: null,
            callsign: null,
            gravatar: null,
        }),

        computed: {
            avatarSize() {
                return this.$_.isUndefined(this.small) ? (this.$_.isUndefined(this.xsmall) ? 200 : 32) : 40;
            }
        },

        filters: {
            splitCallsign(str) {
                const regexNew = /^[A-Z]{4}\d{3}$/i;
                const offset = regexNew.test(str) ? 4 : 3;
                return typeof str === 'string' ? str.substring(0, offset) + '\n' + str.substring(offset) : '';
            }
        },

        watch: {
            user: {
                handler() {
                    this.username = this.user.username || this.user.Username;
                    this.callsign = this.user.callsign || this.user.Callsign;
                    this.gravatar = this.user.gravatar || this.user.Gravatar || md5(this.user.email || this.user.Email || '');
                },
                deep: true
            }
        },

        mounted() {
            this.username = this.user.username || this.user.Username;
            this.callsign = this.user.callsign || this.user.Callsign;
            this.gravatar = this.user.gravatar || this.user.Gravatar || md5(this.user.email || this.user.Email || '');
        }
    }
</script>
